import $axios from '@/utils/request'

// 获取启用的标签
export function getTags (data) {
  const url = '/tag/getTags'
  return $axios.fGet(url, data)
}

// 添加标签
export function addTags (data) {
  const url = '/tag/addTag'
  return $axios.fPost(url, data)
}

// 删除或修改标签名称
export function updateTags (data) {
  const url = '/tag/updateTagById'
  return $axios.fPost(url, data)
}

// 获取标签组列表
export function getTagGroupList (data) {
  const url = '/tagGroup/list'
  return $axios.fPost(url, data)
}

// 编辑标签组
export function editTagGroupList (data) {
  const url = '/tagGroup/edit'
  return $axios.fPost(url, data)
}

// 创建标签组
export function addTagGroupList (data) {
  const url = '/tagGroup/add'
  return $axios.fPost(url, data)
}

// 删除标签组
export function delTagGroupList (data) {
  const url = '/tagGroup/del'
  return $axios.fGet(url, data)
}
